<template>
  <div>
    <!-- 新增编辑地址弹窗 -->
    <a-modal
      v-model:visible="visible"
      :title="isEdit ? '新增地址' : '编辑地址'"
      @cancel="resetFields()"
      @ok="handleOk"
      okText="保存"
      :bodyStyle="{ paddingTop: '40px', paddingLeft: '32px' }"
    >
      <a-form layout="inline" :rules="rules" ref="formRef">
        <a-form-item
          v-bind="validateInfos.province"
          label="地址信息"
          :colon="false"
        >
          <p-cascader
            placeholder="请选择省/市/区"
            v-model:value="stateRef"
            @change="handleCityCheck"
          />
        </a-form-item>

        <a-form-item
          v-bind="validateInfos.details"
          label="详细地址"
          :colon="false"
        >
          <a-textarea
            v-model:value.trim="stateRef.details"
            :maxlength="120"
            placeholder="请输入详细地址信息、如道路、门牌号、小区、楼栋号、单元等信息"
          ></a-textarea>
          <p class="fz-12" style="color:#FF9800">
            注：详细地址长度需要在5-120个汉字或字符，不能包含表情符号
          </p>
        </a-form-item>
        <a-form-item
          v-bind="validateInfos.contact"
          label="收货人姓名"
          :colon="false"
        >
          <a-input
            :maxlength="25"
            placeholder="长度不超过25个字符"
            v-model:value.trim="stateRef.contact"
          ></a-input>
        </a-form-item>
        <a-form-item
          v-bind="validateInfos.contact_phone"
          label="手机号码"
          :colon="false"
        >
          <a-input
            placeholder="请输入6-20个数字"
            v-model:value.trim="stateRef.contact_phone"
          ></a-input>
        </a-form-item>
        <a-form-item
          v-bind="validateInfos.company_name"
          label="企业名称"
          :colon="false"
        >
          <a-input
            placeholder="请输入企业名称"
            v-model:value.trim="stateRef.company_name"
          ></a-input>
        </a-form-item>
        <span>
          <a-checkbox
            v-model:checked="checked"
            class="mt-8"
            style="margin-left:108px;"
            >设置为默认收货地址</a-checkbox
          >
        </span>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="deleteModal"
      :width="288"
      :closable="false"
      @cancel="deleteModal = false"
      @ok="handleDeleteModal"
    >
      <p class="text-center">确定要删除此信息吗？</p>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { getAddressForm, getAddressFormRules } from "./constants/utils";
import { Message } from "ant-design-vue";
import {
  useCompanyAddressAddApi,
  useCompanyAddressUpdateApi,
  useCompanyAddressDeleteApi
} from "@/apis/service";
export default defineComponent({
  setup() {
    const visible = ref(false);
    const isEdit = ref(true);
    const checked = ref(false);
    // form表单对象
    const stateRef = reactive(getAddressForm());
    // form表单对象验证
    const rules = getAddressFormRules();
    const { resetFields, validate, validateInfos } = useForm(stateRef, rules);
    const companyAddressAdd = useCompanyAddressAddApi();
    const companyAddressUpdate = useCompanyAddressUpdateApi();

    //回调函数缓存   操作结束后的后置钩子
    let cb;

    // 省市区选择
    const handleCityCheck = value => {
      stateRef.province = value[0];
      stateRef.city = value[1];
      stateRef.area = value[2];
    };

    // 弹窗确认按钮
    const handleOk = async () => {
      checked.value
        ? (stateRef.is_default = "YES")
        : (stateRef.is_default = "NO");
      try {
        await validate();
        if (isEdit.value) {
          const { id, ...config } = stateRef;
          console.log(id);
          await companyAddressAdd(config);
          Message.success("新增成功!");
          visible.value = false;
        } else {
          await companyAddressUpdate(stateRef);
          Message.success("修改成功!");
          visible.value = false;
        }
        if (cb) cb();
      } catch (error) {
        console.log("error", error);
      }
    };
    // 编辑
    const handleEditClick = (row, callback) => {
      console.log(row);
      visible.value = true;
      isEdit.value = false;
      Object.keys(row).forEach(v => {
        stateRef[v] = row[v];
      });
      //回调函数
      cb = callback;
    };

    // 删除
    const deleteModal = ref(false);
    const deleteId = ref();
    const companyAddressDelete = useCompanyAddressDeleteApi();
    const handleDeleteClick = (row, callback) => {
      deleteModal.value = true;
      deleteId.value = row.id;
      //回调函数
      cb = callback;
    };
    const handleDeleteModal = async () => {
      await companyAddressDelete({
        id: deleteId.value
      });
      Message.success("删除成功!");
      if (cb) cb();
      deleteModal.value = false;
      deleteId.value = null;
    };

    // 新增
    const handleAdd = async callback => {
      visible.value = true;
      isEdit.value = true;
      resetFields();
      //回调函数
      cb = callback;
    };
    return {
      visible,
      isEdit,
      resetFields,
      handleOk,
      rules,
      validateInfos,
      stateRef,
      checked,
      handleCityCheck,
      handleEditClick,
      handleDeleteClick,
      handleDeleteModal,
      deleteModal,
      handleAdd
    };
  }
});
</script>
<style lang="less" scoped>
/deep/.ant-form-item label {
  display: block;
  width: 100px;
  text-align: right;
}
/deep/.ant-form-item label::before {
  content: "";
}
/deep/.ant-form-item label::after {
  content: "";
}
/deep/.ant-form-item input {
  margin-left: 6px;
  border-radius: 5px;
  width: 340px;
}
/deep/.ant-form-item {
  margin-bottom: 16px;
}
</style>
