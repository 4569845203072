import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// 发票管理

// 开票申请列表
export const useInvoiceApplyApi = basicApi({
  url: "/account/invoice/invoicable_page",
  withCredentials: true
});
// 查询默认开票公司信息
export const useInvoiceApplydefaulCompanyApi = basicApi({
  url: "/company_invoice/getDefault",
  withCredentials: true
});
// 查询默认开票地址信息
export const useInvoiceApplyDefaulAddressApi = basicApi({
  url: "/company_address/getDefault",
  withCredentials: true
});
// 申请开票
export const useInvoiceAccountApplyApi = basicApi({
  url: "/account/invoice/apply",
  withCredentials: true
});

// 更新开票
export const useInvoiceAccountUpdateApi = basicApi({
  url: "/account/invoice/update",
  withCredentials: true
});

// 可开票金额
export const useInvoiceAccountTotalApi = basicApi({
  url: "/account/invoice/invoicable_total",
  withCredentials: true
});

// 开票记录列表
export const useInvoiceRecordApi = basicApi({
  url: "/account/invoice/page",
  withCredentials: true
});

// 开票记录详情
export const useInvoiceRecordDetailApi = basicApi({
  url: "/account/invoice/bill_page",
  withCredentials: true
});
// 可开票列表
export const useInvoicablePageApi = basicApi({
  url: "/account/invoice/invoicable_page",
  withCredentials: true
});

// 开票信息列表
export const useCompanyInvoiceListApi = basicApi({
  url: "/company_invoice/list",
  withCredentials: true
});
//查询公司开票信息
export const useCompanyInvoiceQueryApi = basicApi({
  url: "/company_invoice/query",
  withCredentials: true
});

// 开票信息新增
export const useCompanyIncoiceInfoAddApi = basicApi({
  url: "/company_invoice/add",
  withCredentials: true
});

// 开票信息修改
export const useCompanyIncoiceInfoUpdateApi = basicApi({
  url: "/company_invoice/update",
  withCredentials: true
});

// 开票信息修改
export const useCompanyIncoiceInfoDeleteApi = basicApi({
  url: "/company_invoice/delete",
  withCredentials: true
});

// 修改公司开票信息默认状态
export const useCompanyInfoDefaultApi = basicApi({
  url: "/company_invoice/updateDefault",
  withCredentials: true
});

// 地址管理列表
export const useCompanyAddressListApi = basicApi({
  url: "/company_address/list",
  withCredentials: true
});

// 添加公司地址信息
export const useCompanyAddressAddApi = basicApi({
  url: "/company_address/add",
  withCredentials: true
});

// 修改公司地址信息
export const useCompanyAddressUpdateApi = basicApi({
  url: "/company_address/update",
  withCredentials: true
});

// 删除公司地址信息
export const useCompanyAddressDeleteApi = basicApi({
  url: "/company_address/delete",
  withCredentials: true
});

// 修改公司地址默认状态
export const useCompanyAddressDefaultApi = basicApi({
  url: "/company_address/updateDefault",
  withCredentials: true
});
