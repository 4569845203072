<template>
  <div>
    <ul class="addressBox d-flex" style="flex-wrap: wrap;">
      <li
        v-for="item of addressList"
        :key="item.id"
        :class="[
          'px-16',
          'py-20',
          'mb-16',
          chooseItemId === item.id ? 'active' : ''
        ]"
        @click="chooseItem(item.id)"
      >
        <div class="d-flex align-center" style="line-height:16px;">
          <span>{{ item.contact }}</span>
          <span class="ml-10" style="color:#808080;">{{
            item.contact_phone
          }}</span>
        </div>
        <div class="my-8" style="height:1px;background:#D4D4D4;"></div>
        <!-- 地址 -->
        <p style="line-height:18px;color:#808080;">
          {{ item.province }} {{ item.city }} {{ item.area }}
          {{ item.details }}
          <!-- 浙江省 杭州市 西湖区 翠苑街道 万塘路252号计量大厦16楼 -->
        </p>
        <div
          class="d-flex align-center waitshow"
          style="position:absolute;left:20px;bottom:5px;line-height:20px;color:#B99675;"
        >
          <span @click="edit(item)" class="hover">修改</span>
          <span class="ml-10 hover" @click="deleteAddress(item)">删除</span>
        </div>
        <!-- 右上角按钮 -->
        <div
          :class="['waitshow', item.is_default === 'YES' ? 'is_default' : '']"
          style="position:absolute;right:0;top:0;line-height:20px;"
        >
          <span
            v-if="item.is_default === 'YES'"
            class="px-6 py-1"
            style="color:#fff;background:#D4D4D4;"
            >默认地址</span
          >
          <span
            v-else
            class="setDefault px-6 py-1 hover"
            @click="handleDefaultClick(item)"
            >设为默认</span
          >
        </div>
        <!-- 右下角选中 -->
        <div
          class="choose  waitshow"
          style="position:absolute;right:0;bottom:0;border:16px solid #1684FB;border-color:transparent #1684FB #1684FB transparent;"
        >
          <div
            style="width:10px;height:5px;border:1px solid #fff;border-width:0 0 1px 1px;position:absolute;left:3px;top:1px;transform:rotate(309deg)"
          ></div>
        </div>
      </li>
      <li class="addAddressLi text-center pt-20" @click="newAdd">
        <p style="font-size:36px;line-height:30px;">+</p>
        <p>新增地址信息</p>
      </li>
    </ul>
    <update-address ref="addComponent"></update-address>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { state } from "@/store/modules/user";
import { Message } from "ant-design-vue";
import {
  useCompanyAddressListApi,
  useCompanyAddressDefaultApi
} from "@/apis/service";
import updateAddress from "@/components/UpdateAddress/Main.vue";
export default defineComponent({
  setup(props, ctx) {
    //邮寄地址逻辑
    //地址列表  需要公司id
    const companyAddressList = useCompanyAddressListApi();
    const addressList = ref([]);
    const adListInit = async init => {
      const res = await companyAddressList({
        company_id: state.companyId
      });
      addressList.value = res;
      if (init) {
        chooseItem(addressList.value[0]?.id);
      }
      //同步父组件addressListInfo
      ctx.emit("handlerListChange", addressList);
    };
    adListInit(true);
    const chooseItemId = ref();
    const chooseItem = id => {
      if (!id) return;
      chooseItemId.value = id;
      ctx.emit("handlerChange", chooseItemId);
      ctx.emit("handlerListChange", addressList);
    };

    const addComponent = ref();
    //新增地址
    const newAdd = () => {
      if (addressList.value.length > 4) {
        Message.warn("最多添加五条");
        return;
      }
      addComponent.value.handleAdd(() => {
        //新增完重新请求地址列表
        adListInit();
      });
    };
    //删除地址
    const deleteAddress = (row, index) => {
      addComponent.value.handleDeleteClick(row, () => {
        //删除后请求地址列表
        addressList.value.splice(index, 1);
        ctx.emit("handlerListChange", addressList);
      });
    };

    //修改地址
    const edit = row => {
      addComponent.value.handleEditClick(row, () => {
        //修改完重新请求地址列表
        adListInit();
      });
    };

    // 设置为默认
    const companyAddressDefault = useCompanyAddressDefaultApi();
    const handleDefaultClick = async row => {
      await companyAddressDefault({
        id: row.id
      });
      Message.success("设置成功!");
      addressList.value.forEach(address => {
        if (address.is_default === "YES") {
          address.is_default = "NO";
        }
        if (address.id === row.id) {
          address.is_default = "YES";
        }
      });
    };

    return {
      addressList,
      chooseItemId,
      chooseItem,
      newAdd,
      addComponent,
      handleDefaultClick,
      deleteAddress,
      edit
    };
  },
  components: { updateAddress }
});
</script>
<style scoped lang="less">
.addressBox {
  li {
    width: 254px;
    height: 118px;
    border: 1px solid #d4d4d4;
    margin-right: 16px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    &.active {
      border-color: #1684fb;
      .waitshow {
        display: block;
      }
      .setDefault {
        background: #fff;
        color: #b99675;
        &:hover {
          color: #1684fb;
        }
      }
    }

    &:hover {
      border-color: #1684fb;
    }
    .waitshow {
      display: none;
    }
    .is_default {
      display: block;
    }
  }
  li:nth-of-type(3n) {
    margin-right: 0;
  }
  .addAddressLi:hover {
    color: #1684fb;
    border: 1px dashed #1684fb;
  }
  .hover:hover {
    cursor: pointer;
  }
}
</style>
