import * as rule from "@wlhy-web-lib/rule/esm";

// 开票信息
export const getManagerForm = () => ({
  invoice_title: null,
  tax_number: null
});

export const getManagerFormRules = () => {
  const rules = rule.object({
    invoice_title: [rule.required("发票抬头  不能为空").blur()],
    tax_number: [rule.required("纳税人识别号  不能为空").blur()]
  });

  return {
    ...rules
  };
};

// 地址管理
export const getAddressForm = () => ({
  province: "",
  city: "",
  area: "",
  details: "",
  contact: "",
  contact_phone: "",
  company_name: "",
  is_default: "NO" // YES：是 NO:否
});

export const getAddressFormRules = () => {
  const rules = rule.object({
    province: [rule.required("地址信息 不能为空").message("地址信息 不能为空")],
    details: [
      rule.required("详细地址 不能为空").message("详细地址 不能为空"),
      rule
        .string("")
        .min(5)
        .max(120)
        .message("详细地址长度在5-120个字符之间")
        .change()
    ],
    contact: [
      rule.required("收货人姓名 不能为空").message("收货人姓名  不能为空"),
      rule
        .string("")
        .min(1)
        .max(25)
        // .pattern(/^[\u0391-\uFFE5A-Za-z]+$/) // 不强制校验
        .message("收货人姓名不能超过25个字符")
        .blur()
    ],
    contact_phone: [
      rule.required("手机号码  不能为空").message("手机号码  不能为空"),
      rule.string("手机号码").phone()
    ],
    company_name: [
      rule.required("企业名称  不能为空").message("企业名称  不能为空")
    ]
  });
  console.log("rules", rules);
  return {
    ...rules
  };
};
